.swiper-slide {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
}

.centered-image {
  width: 500px;
  height: 500px;
  object-fit: contain;
  display: block;
  margin: auto;
}
