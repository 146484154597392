/* https://css-irl.info/animating-underlines/ */

.nav-category-button::after {
  content: "";
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 0.1em;
  background-color: var(--primary-color);
  opacity: 0;
  transition: opacity 300ms, transform 300ms;
}

.nav-category-button:hover::after,
.nav-category-button:focus::after {
  opacity: 1;
  transform: translate3d(0, 0.2em, 0);
}

.nav-category-button {
  color: inherit;
  text-decoration: none;
}

.nav-category-button {
  display: block;
  position: relative;  
}

.nav-category-button {
  overflow: hidden;
}

.nav-category-button::after {
  opacity: 1;
  transform: translate3d(-100%, 0, 0);
}

.nav-category-button:hover::after,
.nav-category-button:focus::after {
  transform: translate3d(0, 0, 0);
}
