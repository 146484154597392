@tailwind base;
@tailwind components;
@tailwind utilities;

:root {
  --primary-color: #ffc300;
  --secondary-color: #003566;
  --accent-color: #ffd60a;
  --background-color: #001d3d;
  --text-color: #000814;
}

body {
  margin: 0;
  font-family: "Oswald", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}
#root {
  min-height: 100vh;
  display: flex;
  flex-direction: column;
}

.footer {
  margin-top: auto;
}
